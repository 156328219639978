const content = {
  title: 'Grundfähigkeitsversicherung - Highlights',
  headvisualHeadline: 'Grundfähigkeitsversicherung - Highlights',
  sectionHead: {
    headline:
      'Genügend Arbeit – zu wenig qualifizierte Fachkräfte. Kennen Sie das?',
    paragraph:
      'Der demographische Wandel macht auch vor dem Arbeitsmarkt nicht halt: Die Auswirkungen sind branchenübergreifend sehr deutlich zu spüren und in vielen Wirtschaftszweigen fehlt qualifiziertes Personal. Der Ausblick auf die Zukunft verspricht nicht allzu viel Positives: mit dem Trend des Studierens an Hochschulen absolvieren immer weniger Schulabgänger eine Berufsausbildung, was zu Folge hat, dass bei vielen Unternehmen mit klassischen Berufen der Nachwuchs ausbleibt. Somit können sich Fachkräfte zunehmend zwischen verschiedenen Arbeitgebern entscheiden.'
  },
  bedeutung: {
    headline: 'Was kann das für Sie bedeuten?',
    listItems: [
      'Höhere Fluktuation durch einen verschärften Wettbewerb (Hohe Fluktuationskosten)',
      'Überalterung der Belegschaft (Hoher Krankenstand, Wunsch nach vorzeitiger Rente und Vorzeitiges Ausscheiden aufgrund von Berufsunfähigkeit)',
      'Mehrarbeit Ihrer Mitarbeiter führt zu einer Zunahme des Krankenstands',
      'Umsatzeinbußen aufgrund Ablehnung lukrativer Aufträge durch fehlender Mitarbeiter',
      'Termine können nicht eingehalten werden'
    ]
  },
  halvedContent: [
    {
      anchor: 'spediteure',
      background: 'light-grey-blue',
      backgroundPosition: 'center-33',
      headline: 'Highlights für Spediteure',
      paragraphs: [
        '<strong>Individuell für Berufskraftfahrer – Ihr Alleinstellungsmerkmal.</strong>',
        'Die Herausforderung für Sie als Arbeitgeber: Sie müssen sich am Arbeitsmarkt als starke Marke positionieren. Die NÜRNBERGER hilft Ihnen dabei neue Fachkräfte zu gewinnen und dauerhaft zu binden - denn für Ihre Mitarbeiter gibt es jetzt ein echtes Highlight.',
        '<strong>Absicherung der geistigen und körperlichen Fähigkeiten + Absicherung des Fahrlizenzverlusts Klasse C oder D aus gesundheitlichen Gründen.</strong>'
      ],
      buttonText: 'Leistungsbeispiel',
      buttonVariant: 'gray-blue',
      dialogTitle: 'Leistungsbeispiel',
      dialogParagraphs: [
        'Peter ist leidenschaftlicher LKW-Fahrer. Er könnte sich keine andere Arbeit vorstellen - schon gar nicht im Büro. Immer auf Achse, rauf auf den Bock - und schon geht es zum nächsten Termin. Er ist am liebsten auf der Straße unterwegs, im Sommer wie im Winter.',
        'Im Rahmen der ärztlichen Kontrolluntersuchung zur Verlängerung seiner Fahrlizenz wird bei ihm eine schwere Herzerkrankung festgestellt. Infolgedessen wird seine Fahrerlaubnis der Klasse C aus gesundheitlichen Gründen nicht verlängert.',
        'Zum Glück hat Peter seine körperlichen und geistigen Fähigkeiten abgesichert. Mit der NÜRNBERGER Grundfähigkeitsversicherung geht das über seinen Arbeitgeber sogar besonders günstig. Darüber hinaus hat sich Peter für den optionalen Zusatzbaustein "Fahrlizenzverlust Klasse C oder D" entschieden. So erhält er nun die vereinbarte monatliche Grundfähigkeitsrente, die ihm dabei hilft sein Leben neu zu organisieren.'
      ]
    },
    {
      anchor: 'gesundheitswesen',
      background: 'light-pink',
      backgroundPosition: '75',
      headline: 'Highlights für das Gesundheitswesen',
      paragraphs: [
        '<strong>Berufe im Gesundheitswesen sind oftmals psychisch sehr belastend  – hier gibt es eine Lösung!</strong>',
        'Die Herausforderung für Sie als Arbeitgeber: Sie müssen sich am Arbeitsmarkt als starke Marke positionieren. Die NÜRNBERGER hilft Ihnen dabei neue Fachkräfte zu gewinnen und dauerhaft zu binden - denn für Ihre Mitarbeiter gibt es jetzt ein echtes Highlight.',
        '<strong>Absicherung der geistigen und körperlichen Fähigkeiten + Absicherung volle Erwerbsminderung infolge psychischer Erkrankung</strong>'
      ],
      buttonText: 'Leistungsbeispiel',
      buttonVariant: 'gray-blue',
      dialogTitle: 'Leistungsbeispiel',
      dialogParagraphs: [
        'Elke ist seit über 10 Jahren als mobile Altenpflegerin tätig. Sie liebt die anspruchsvolle Arbeit. Menschen zu helfen, erfüllt sie. Doch Pflege und Zuwendung zu geben verlangt ihr manchmal alles ab. Und der nächste Patient wartet schon. Doch was passiert wenn sie ihren Beruf (vorübergehend) nicht mehr ausüben kann?',
        'Hier hilft die NÜRNBERGER Grundfähigkeitsversicherung.',
        'Kein Arbeitstag gleicht dem anderen. Als mobile Altenpflegerin ist Flexibilität gefragt: Ob körperbezogene Pflegemaßnahmen, Hilfe bei der Haushaltsführung oder der Beratung der Pflegebedürftigen und Angehörigen bei Fragen zur Pflege. All dass sind Aufgaben die Elke täglich lösen muss.',
        'Neben der geforderten Flexibilität ist der Beruf als mobile Altenpflegerin auch körperlich sehr fordernd. Das schwere Heben von Patienten und das ständige Bücken führen zu immer heftigeren Rückenbeschwerden bei Elke. Es kommt dadurch zu Taubheitsgefühlen und Lähmungserscheinungen in den Armen. Ein schwerer Bandscheibenvorfall wird bei ihr diagnostiziert.',
        'Zum Glück hat Elke ihre körperlichen und geistigen Fähigkeiten abgesichert. Mit der NÜRNBERGER Grundfähigkeitsversicherung geht das über ihren Arbeitgeber sogar besonders günstig. So erhält sie nun die vereinbarte monatliche Grundfähigkeitsrente, die ihr dabei hilft ihr Leben neu zu organisieren.'
      ]
    },
    {
      anchor: 'handwerker',
      background: 'light-grey-blue',
      backgroundPosition: '25',
      headline: 'Highlights für Handwerker',
      paragraphs: [
        '<strong>Gerade Handwerksberufe sind aufgrund schlechter Bezahlung für junge Leute oft nicht interessant – aber hier gibt es eine Lösung!</strong>',
        'Sie müssen sich am Arbeitsmarkt mit Ihrem Unternehmen als attraktiver Arbeitgeber positionieren. Die NÜRNBERGER hilft Ihnen dabei neue Fachkräfte zu gewinnen und dauerhaft zu binden - denn für Ihre Mitarbeiter gibt es jetzt ein echtes Highlight.',
        '<strong>Absicherung der geistigen und körperlichen Fähigkeiten + Fahrlizenzverlust Klasse B</strong>'
      ],
      buttonText: 'Leistungsbeispiel',
      buttonVariant: 'gray-blue',
      dialogTitle: 'Leistungsbeispiel',
      dialogParagraphs: [
        'Als Schreiner übt Phillipp ein anspruchsvolles Handwerk aus. Mit Holz zu arbeiten ist seine Leidenschaft und hat große Freude daran individuelle Möbelstücke zu erschaffen. Er kann sich keinen anderen Beruf vorstellen.',
        'In seiner Freizeit ist Philipp Vollblut-Fußballer. Bei einem Freundschaftsspiel verletzt er sich schwer am Knie. Mehrere Operationen und eine mehrmonatige Reha sind die Folge. In dieser Zeit ist Philipp in seiner Fähigkeit zu knien so stark beeinträchtigt, dass er nicht mehr in der Lage ist, sich auf den Boden zu knien und sich aus eigener Kraft (auch mit Abstützen) wieder aufzurichten. Damit sind die Voraussetzungen des Leistungsauslösers "Knien und Bücken" erfüllt und Philipp bekommt die vereinbarte Grundfähigkeitsrente.',
        'Zum Glück hat Philipp seine körperlichen und geistigen Fähigkeiten abgesichert. Mit der NÜRNBERGER Grundfähigkeitsversicherung geht das über seinen Arbeitgeber sogar besonders günstig.'
      ]
    },
    {
      anchor: 'kfz-handwerk',
      background: 'light-blue',
      headline: 'Highlights für das KFZ-Handwerk',
      paragraphs: [
        'Immer mehr Werkstätten haben zunehmend Probleme, qualifizierte Fachkräfte zu finden. Erschwerend kommt hinzu, dass einige Berufsanfänger Qualifikationslücken aufweisen beziehungsweise sich viele Schulabgänger für ein Studium entscheiden und damit die „Überakademisierung“ vorantreiben. Viele Betriebe sehen sich somit einer geringen Anzahl an geeigneten Auszubildenden und Fachkräften gegen-übergestellt. Sie müssen sich also am Arbeitsmarkt mit Ihrem Unternehmen als attraktiver Arbeitgeber positionieren.',
        'Die NÜRNBERGER hilft Ihnen dabei neue Fachkräfte zu gewinnen und dauerhaft zu binden - denn für Ihre Mitarbeiter gibt es jetzt ein echtes Highlight.',
        '<strong>Absicherung der geistigen und körperlichen Fähigkeiten + Fahrlizenzverlust Klasse B</strong>'
      ],
      buttonText: 'Leistungsbeispiel',
      buttonVariant: 'gray-blue',
      dialogTitle: 'Leistungsbeispiel',
      dialogParagraphs: [
        'Neben der handwerklichen Arbeit, die ihm Spaß macht, ist Matthias vom ständigen technischen Fortschritt fasziniert und hält sich immer auf dem Laufenden.',
        'Nach einem langen Arbeitstag genießt er es deshalb, noch ein paar Kilometer mit seinem Motorrad zu fahren. Bei einer seiner Runden kommt es aufgrund eines unachtsamen Autofahrers zu einem folgenschweren Unfall: Matthias stürzt und bricht sich die rechte Schulter. Mehrere Operationen mit entsprechenden Krankenhausaufenthalten sowie eine Reha sind notwendig. Die Verletzung der Schulter führt dazu, dass die nächsten 8 Monate in seiner Bewegung des rechten Arms sehr stark eingeschränkt ist. Damit sind die Voraussetzungen des Leistungsauslösers "Gebrauch eines Arms" erfüllt und er erhält die vereinbarte Grundfähigkeitsrente.',
        'Zum Glück schlagen die Behandlung und die Reha an. So kann Matthias bereits nach 9 Monaten seinen Job als KFZ-Mechatroniker wieder voll ausüben. Während der 9-monatigen Behandlungsdauer hat sich Matthias zumindest wegen der finanziellen Unterstützung keine Sorgen machen müssen, da er die NÜRNBERGER Grundfähigkeitsversicherung abgeschlossen hat. Mit der Unterstützung seines Arbeitgebers ist diese Absicherung sogar besonders günstig!'
      ]
    }
  ]
};

export default content;
