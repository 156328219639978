import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  HalvedContent,
  Dialog,
  SectionHead,
  ContentBox,
  List,
  Paragraphs
} from 'src/components';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/gfv-highlights';
import subpageNavigations from 'src/data/subpage-navigations';
import ArrowIcon from 'src/assets/icons/arrow.svg';

const GFVHighlightsPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline
    }}
    subpageNavItems={subpageNavigations.betrieblicherEinkommensschutz}
    activeNavItem={3}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.sectionHead.headline} centered>
        <p>{content.sectionHead.paragraph}</p>
      </SectionHead>
      <HalvedContent align="center">
        <BackgroundImage fluid={data.manBusinessWithLaptopOutside.childImageSharp.fluid} />
        <ContentBox
          headline={content.bedeutung.headline}
          background="light-blue"
          wider
        >
          <List items={content.bedeutung.listItems} icon={ArrowIcon} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section className="no-children-margin">
      {content.halvedContent.map((item, index) => (
        <React.Fragment key={index}>
          <div id={item.anchor}></div>
          <HalvedContent swapped={(index + 1) % 2 !== 0}>
            <BackgroundImage
              fluid={
                data[`halvedContentBackground${index + 1}`].childImageSharp
                  .fluid
              }
              className={`${item.backgroundPosition ? `bg-${item.backgroundPosition}` : ''}`}
            />
            <ContentBox
              background={item.background}
              headline={item.headline}
              align={(index + 1) % 2 ? 'right' : 'left'}
              wider
            >
              <Paragraphs items={item.paragraphs} innerHTML />
              <Dialog
                buttonText={item.buttonText}
                buttonVariant={item.buttonVariant}
                title={item.dialogTitle}
              >
                <Paragraphs items={item.dialogParagraphs} />
              </Dialog>
            </ContentBox>
          </HalvedContent>
        </React.Fragment>
      ))}
    </section>
  </MainLayout>
);

export default GFVHighlightsPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-dark-long-hair.jpg" }
    ) {
      ...image
    }
    manBusinessWithLaptopOutside: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-busines-with-laptop-outside.jpg" }
    ) {
      ...image
    }
    halvedContentBackground1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "trucker.jpg" }
    ) {
      ...image
    }
    halvedContentBackground2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "nurse.jpg" }
    ) {
      ...image
    }
    halvedContentBackground3: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-in-workshop.jpg" }
    ) {
      ...image
    }
    halvedContentBackground4: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "mechanic.jpg" }
    ) {
      ...image
    }
  }
`;
